import { Span, Text, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import { TabRouterPath } from "@src/app/routing/constant/tabRoute";
import { APP_V2_APP_EVENTS, CommonDialog } from "@src/appV2/lib";
import { logError } from "@src/appV2/lib/analytics";
import { useAcceptOrDeclineShiftInvite } from "@src/appV2/Shifts/ShiftInvites/api/useAcceptOrDeclineShiftInvite";
import { ShiftInvite, ShiftInviteStatus } from "@src/appV2/Shifts/ShiftInvites/types";
import { uniq } from "lodash";
import pluralize from "pluralize";
import { useHistory } from "react-router-dom";

interface ShiftBookingConflictingInviteDialogProps {
  modalState: UseModalState;
  conflictingInvites: ShiftInvite[];
  openBookingWarningModal: () => void;
}

export function ShiftBookingConflictingInviteDialog(
  props: Readonly<ShiftBookingConflictingInviteDialogProps>
) {
  const { modalState, conflictingInvites, openBookingWarningModal } = props;
  const pluralizedInvite = pluralize("invite", conflictingInvites.length);

  const facilityNames = uniq(conflictingInvites.map((invite) => invite.attributes.facility.name));

  const facilityNameText =
    facilityNames.length <= 1
      ? facilityNames[0]
      : `${facilityNames.slice(0, -1).join(", ")} and ${facilityNames.at(-1)!}`;

  const { mutateAsync: mutateAcceptOrDecline, isLoading: isProcessingDeclineShiftInvites } =
    useAcceptOrDeclineShiftInvite();
  const history = useHistory();

  return (
    <CommonDialog
      modalState={modalState}
      title={
        <>
          You have{" "}
          <Span color={(theme) => theme.palette.primary.main}>{conflictingInvites.length}</Span>{" "}
          conflicting shift {pluralizedInvite}
        </>
      }
      actions={
        <>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={isProcessingDeclineShiftInvites}
            onClick={async () => {
              modalState.closeModal();
              openBookingWarningModal();
              try {
                await Promise.all(
                  conflictingInvites.map((invite) =>
                    mutateAcceptOrDecline({
                      shiftInviteId: invite.id,
                      status: ShiftInviteStatus.DECLINED,
                    })
                  )
                );
              } catch (error) {
                logError(APP_V2_APP_EVENTS.SHIFT_INVITE_ERROR, { error });
              }
            }}
          >
            Book Anyway
          </Button>
          <Button
            fullWidth
            variant="outlined"
            disabled={isProcessingDeclineShiftInvites}
            onClick={() => {
              modalState.closeModal();
              history.push(TabRouterPath.OPEN_SHIFTS);
            }}
          >
            Go Back
          </Button>
        </>
      }
    >
      <Stack spacing={1}>
        <Text paragraph>
          This shift overlaps with your {pluralizedInvite} at {facilityNameText}. By booking this
          shift, your {conflictingInvites.length === 1 ? "" : conflictingInvites.length} conflicting
          shift {pluralizedInvite} will be automatically declined.
        </Text>
      </Stack>
    </CommonDialog>
  );
}
